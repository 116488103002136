import styled from 'styled-components';
import { ReactTabulator as oReactTabulator } from 'react-tabulator';

import 'react-tabulator/lib/styles.css';
// import 'react-tabulator/lib/css/tabulator.min.css';
// import 'react-tabulator/css/bootstrap/tabulator_bootstrap.min.css';

import 'react-tabulator/css/tabulator_site.min.css';
// import './template.css';
import './custom.css';

export const ReactTabulator = styled(oReactTabulator)`
  .tabulator-col {
    /* background-color: #1c142e !important; */
    background-color: #effffa !important;
  }

  .tabulator-header {
    border-bottom: 2px solid #00bfff !important;
  }

  .tabulator-col-title {
    color: #3079ff;
  }

  .tabulator-footer {
    /* background-color: #1c142e !important; */
    background-color: #effffa !important;
    border-top: 2px solid #00bfff !important;
    padding-top: 7px !important;
  }
`;
