import React from 'react';
import PropTypes from 'prop-types';
import { ChatNew } from '@styled-icons/remix-line';

import * as S from './styles';

const BadgeArtist = ({ propLink, propText, propClickAction, propId }) => {
  return (
    <S.Link onClick={() => propClickAction(propId)} to={propLink}>
      {propText}
    </S.Link>
  );
};

BadgeArtist.defaultProps = {
  propId: '[undefined]',
  propLink: '#',
  propText: '[undefined]',
  propClickAction: () => {},
};

BadgeArtist.propTypes = {
  propId: PropTypes.string,
  propLink: PropTypes.string,
  propText: PropTypes.string,
  propClickAction: PropTypes.func,
};

export default BadgeArtist;
