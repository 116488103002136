import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const Loader = ({ propType }) => {
  return (
    <S.Container>
      <S.Spinner type={propType} color="#3079ff" height={60} width={60} />
    </S.Container>
  );
};

Loader.defaultProps = {
  propType: 'Grid',
};

Loader.propTypes = {
  propType: PropTypes.oneOf([
    'Audio',
    'BallTriangle',
    'Bars',
    'Circles',
    'Grid',
    'Hearts',
    'Oval',
    'Puff',
    'Rings',
    'TailSpin',
    'ThreeDots',
  ]),
};

export default Loader;
