import styled from 'styled-components';
import media from 'styled-query';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);
  margin-top: 75px;

  background-color: #fff;

  padding: 5vw;
  /* text-align: center; */
  /* background-color: #c4d8ff; */
`;

export const ContainerHeader = styled.div`
  margin-bottom: 5vh;
`;

export const Title = styled.h1`
  color: #3079ff;
`;

export const ContainerContent = styled.div`
  height: 100%;
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DivPlayer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;

  margin: 0 1rem 1rem 1rem;
  /* width: calc(25% - 2rem); */

  width: calc(${props => props.propW} - 2rem);

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    width: 100%;
    margin: 0;

    div {
      width: 100% !important;
      height: auto !important;
    }
  `}
`;
