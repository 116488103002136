const ledGreen = '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #ABFF00; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;"></div>';
const ledGreenDark = '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #3c763d; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89ff00 0 2px 12px;"></div>';
const ledBlue = '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #24E0FF; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px, #3f8cff 0 2px 12px;"></div>';
const ledBlueDark = '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #2474ff; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px, #3f8cff 0 2px 12px;"></div>';
const ledYellow = '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #FF0; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 12px;"></div>';
const ledRed = '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #FF0000; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #470101 0 -1px 9px, #ff0000 0 2px 12px;"></div>';
const ledPurple = '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #ff10f8; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #470130 0 -1px 9px, #cd07ff 0 2px 12px;"></div>';
const ledNull = '';

export function led(status, isJustTrue) {
  if (isJustTrue) {
    return status === 0 ? ledGreen : ledNull;
  }
  switch (status) {
    case 0:
      return ledGreen
    case 1:
      return ledBlue
    case 2:
      return ledRed
    case 3:
      return ledNull
    default:
      return ledPurple
  }
}
