import styled from 'styled-components';
import media from 'styled-query';

import imgBackground from '../../assets/backgrounds/background.png';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  height: 100vh;

  /* background: #1C242E; */
  background-image: url(${imgBackground});
  background-size: cover;
`;

export const DivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  align-items: center;

  justify-content: space-between;

  border-radius: 5px;
  /* border: 0.5px solid #1C242E; */

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    width: 70vw;
    height: 80vh;

  `}

  ${media.between('medium', 'large')`
    /* screen width is between 768px (medium) and 1170px (large) */
    /* background: green; */
    width: 50vw;
    height: 50vh;

    form {
      margin-top: 7%;
    }
  `}

  ${media.greaterThan('large')`
    /* screen width is greater than 1170px (large) */
    /* background: blue; */
    width: 50vw;
    height: 50vh;

    form {
      margin-top: 6%;
    }
  `}
`;

export const Img = styled.img`
  width: 150px;
  height: auto;
`;

export const Div = styled.div`
  padding: 25% 15px;
`;

export const H1 = styled.h1`
  color: #00ffaa;
`;

export const DivContent = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const H3 = styled.h3`
  color: #00ffaa;
`;

export const Text = styled.span`
  color: #1ab7d9;
  margin-left: 10px;
`;

export const DivText = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const DivButton = styled.div`
  width: 150px;
`;

export const Button = styled.button`
  width: 100%;
  height: 45px;
  background: #00ffaa;
  background-image: linear-gradient(to right, #00ffaa, #3079ff);
  color: #1c242e;
  border: none;
  border-radius: 25px;
  transition: all 2s;

  &:hover {
    background-image: none;
    /* background: #00bfff; */
    background: #3079ff;
  }
`;
