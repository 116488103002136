import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authIsAuthenticated } from '../../services/auth';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      const auth = await authIsAuthenticated();
      userHasAuthenticated(auth);
    } catch (e) {
      userHasAuthenticated(false);
    }
    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <Route
        {...rest}
        render={props =>
          !isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: '/', state: { from: props.location } }}
            />
          )
        }
      />
    )
  );
};

export default AuthenticatedRoute;
