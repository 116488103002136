import { Auth } from 'aws-amplify';

// export const authIsAuthenticated = () => true;
export const authIsAuthenticated = async () => {
  try {
    console.log('=> authLogin -> Start');
    await Auth.currentSession();
    console.log('=> authLogin -> End -> Auth');
    return true;
  } catch (e) {
    console.log('=> authLogin -> End -> NoAuth');
    return false;
  }
};

// AUTH
export const authSignUp = async (email, password) => {
  try {
    console.log('Sign Up');
    // const authNew = await Auth.signUp({
    //   username: email,
    //   password,
    //   attributes: {
    //     'custom:useName': 'useName',
    //     'custom:useNameLast': 'useNameLast',
    //     'custom:useCodeReference': '000',
    //   },
    // });
    // console.log(authNew);
    // console.log('Register -> Ok');
    console.log('Register -> Disabled');
  } catch (error) {
    console.error(error);
    console.log('Register => Error');
  }
};

export const authSignIn = async (email, password) => {
  try {
    console.log('=> authSignIn -> Start');
    const login = await Auth.signIn(email, password);
    console.log(login);
    console.log('=> authSignIn -> End');
    return { resStatus: true, resMessage: 'Login Success' };
  } catch (e) {
    console.error(e.message);
    return { resStatus: false, resMessage: e.message };
  }
};

export const authSignOut = () => {
  console.log('=> authLogout -> Start');
  Auth.signOut();
  console.log('=> authLogout -> End');
};
