import React from 'react';
import PropTypes from 'prop-types';
import { ChatNew } from '@styled-icons/remix-line';

import * as S from './styles';

const ButtonAdd = ({ propLink, propText, propClickAction }) => {
  return (
    <S.Link onClick={propClickAction} to={propLink}>
      <S.IconWrapper>
        <ChatNew />
      </S.IconWrapper>
      {propText}
    </S.Link>
  );
};

ButtonAdd.defaultProps = {
  propLink: '#',
  propText: '',
  propClickAction: () => {},
};

ButtonAdd.propTypes = {
    propLink: PropTypes.string,
    propText: PropTypes.string,
    propClickAction: PropTypes.func,
};

export default ButtonAdd;
