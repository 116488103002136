import Amplify from 'aws-amplify';
import config from '../config/aws';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  // Storage: {
  //   AWSS3: {
  //     bucket: config.s3.BUCKET,
  //     region: config.s3.REGION,
  //   },
  // },
  Storage: {
    region: config.S3_THUMBNAILS_PROFILE.REGION,
    bucket: config.S3_THUMBNAILS_PROFILE.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: config.info.NAME,
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});
