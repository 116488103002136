import styled from 'styled-components';
import { Link as oLink } from 'react-router-dom';

export const Link = styled(oLink)`
  display: flex;
  width: 100%;
  align-items: center;
  text-decoration: none;
  color: #606675;
  background: #eaeff5;
  &:hover {
    color: #e05959;
    border: 1px solid #e05959;
  }
  border: 1px solid #6066756e;
  padding: 3px 5px;
  border-radius: 5px;
`;

export const IconWrapper = styled.div`
  //Preenchimento Inicial
  fill: #bbb;
  width: 30px;
  height: 30px;
`
