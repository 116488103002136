import React from 'react';

import Header from './Header';
import HeaderLinks from './HeaderLinks';

function NavBar() {
  return (
    <Header
      color="primary"
      brand="Material Kit React"
      rightLinks={<HeaderLinks />}
      fixed
      // absolute
      // changeColorOnScroll={{
      //   height: 400,
      //   color: "transparent"
      // }}
    />
  );
}

export default NavBar;
