import styled from 'styled-components';
import Loader from 'react-loader-spinner';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export const Spinner = styled(Loader)`
  margin-right: 15px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  background: #00ffaa;
  background-image: linear-gradient(to right, #00ffaa, #3079ff);
  color: #1c242e;
  border: none;
  border-radius: 25px;
  transition: all 2s;

  &:hover {
    background-image: none;
    /* background: #00bfff; */
    background: #3079ff;
  }

  &:disabled {
    cursor: progress;
  }
`;
