import styled from 'styled-components';
import { Link as oLink } from 'react-router-dom';

export const Link = styled(oLink)`
  text-decoration: none;
  color: #00bfff;
  &:hover {
    color: #00ffaa;
  }
`;
