import styled from 'styled-components';
import Loader from 'react-loader-spinner';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export const Spinner = styled(Loader)`
  margin-right: 15px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${props => `${props.propHeight}px`};
  background: #3079ff;
  color: white;
  border: 1px solid #3079ff;
  border-radius: 10px;
  transition: all 1s;

  &:hover {
    color: white;
    color: #3079ff;
    border: 1px solid #3079ff;
    background-color: #03163d;
  }

  &:disabled {
    cursor: progress;
  }
`;
