import styled from 'styled-components';
import media from 'styled-query';
import { Link as oLink } from 'react-router-dom';
import {
  Form as oForm,
  Input as oInput,
  Select as oSelect,
} from '@rocketseat/unform';

export const Container = styled.div`
  display: flex;
  flex: 1;
  header {
    background-color: #e9f6ff;
    span {
      color: #3079ff !important;
      /* background-color: #3079ff !important; */
    }
  }
`;

export const DivTab = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Form = styled(oForm)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  flex: 1;

  small {
    /* color: #3079ff; */
    color: #4a8aff;
    margin-left: 3px;
    margin-bottom: 5px;
  }

  img {
    width: 100%;
  }

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    flex-direction: column;
  `}
`;

export const Input = styled(oInput)`
  background: #fff;
  border: 1px solid #00bfff;
  color: #545454;
  border-radius: 5px;
  font-size: 15px;

  &:disabled {
    background: #a7bad43d;
    color: #545454b5;
    cursor: not-allowed;
  }
`;

export const InputD = styled.input`
  background: #fff;
  border: 1px solid #00bfff;
  color: #545454;
  border-radius: 5px;
  font-size: 15px;

  &:disabled {
    background: #a7bad43d;
    color: #545454b5;
    cursor: not-allowed;
  }
`;

export const Select = styled(oSelect)`
  background: #fff;
  border: 1px solid #00bfff;
  color: #545454;
  border-radius: 5px;
  height: 35px;
  font-size: 15px;

  &:disabled {
    background: #a7bad43d;
    color: #545454b5;
    cursor: not-allowed;
  }
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  margin: 0 1rem 1rem 1rem;
  /* width: calc(25% - 2rem); */

  width: calc(${props => props.propW} - 2rem);

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    width: 100%;
    margin: 0;
  `}
`;

export const DivInputLink = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;

  margin: 0 1rem 1rem 1rem;
  /* width: calc(25% - 2rem); */

  width: calc(${props => props.propW} - 2rem);

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    width: 100%;
    margin: 0;
  `}
`;

export const LabelInputImage = styled.label`
  input {
    display: none;
  }
  img {
    border: 1px dotted #e71a6938;
  }
  img:hover {
    cursor: pointer;
  }
`;

export const DivTabLink = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const DivLink = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  /* background-color: red; */

  margin: 0 1rem 1rem 1rem;
  /* width: calc(25% - 2rem); */

  width: calc(${props => props.propW} - 2rem);

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    width: 100%;
    margin: 0;
  `}
`;

export const DivPlayer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;

  margin: 0 1rem 1rem 1rem;
  /* width: calc(25% - 2rem); */

  width: calc(${props => props.propW} - 2rem);

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    width: 100%;
    margin: 0;

    div {
      width: 100% !important;
      height: auto !important;
    }
  `}
`;

export const DivButtonCheck = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px 0;

  margin: 0 1rem 1rem 1rem;
  /* width: calc(25% - 2rem); */

  width: calc(${props => props.propW} - 2rem);

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    width: 100%;
    margin: 0;
  `}
`;

export const DivButtonCheckLink = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  margin: 0 1rem 1rem 1rem;
  /* width: calc(25% - 2rem); */

  width: calc(${props => props.propW} - 2rem);

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    width: 100%;
    margin: 0;
  `}
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 25px 0;
  width: 100%;
  button {
    width: 150px;
  }
`;

export const DivLinkButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 25px 0;
  width: 100%;
`;

export const Button = styled.button`
  width: 80%;
  max-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  background: transparent;
  color: ${props => (props.color ? props.color : '#3079ff')};
  border: 1px solid ${props => (props.color ? props.color : '#3079ff')};
  border-radius: 10px;
  transition: all 1s;

  &:hover {
    color: ${props => (props.color ? props.color : '#3079ff')};
    border: 2px solid ${props => (props.color ? props.color : '#3079ff')};
  }

  &:disabled {
    cursor: progress;
  }
`;

export const DivButtonTest = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: flex-end;
  justify-content: flex-end;

  margin: 0 1rem 1rem 1rem;
  /* width: calc(25% - 2rem); */

  width: calc(${props => props.propW} - 2rem);

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    width: 100%;
    margin: 0;
  `}
`;

export const Link = styled(oLink)`
  width: 100%;
  text-decoration: none;
  button {
    width: 100% !important;
  }
`;

export const DivMovie = styled.div`
  display: flex;
  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    flex-direction: column;
  `}
`;
