import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';
import { NotificationContainer } from 'react-notifications';
import api from '../../services/api';
import InfluencerManagerContext from './InfluencerManagerContext';

import NavBar from '../../components/NavBar';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import Line from '../../components/Line';
import NavTabsGeneral from '../../components/NavTabsGeneral';

import TabInfo from './Tabs/Info';
import TabImage from './Tabs/Image';
import TabUser from './Tabs/User';

import * as S from './styles';

const InfluencerManager = props => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [isContent, setContent] = useState(false);
  const [isNewData, setNewData] = useState(false);
  const [objData, setData] = useState({});

  const auxFormatData = data => {
    data.id = data.infId;
    switch (data.infStatus) {
      case 0:
        data._infStatus = 'Active';
        break;
      case 2:
        data._infStatus = 'Disabled';
        break;
      default:
        data._infStatus = 'Disabled';
        break;
    }
    switch (data.infEmailAuth) {
      case 0:
        data._infEmailAuth = 'Authenticated';
        break;
      case 2:
        data._infEmailAuth = 'Unauthenticated';
        break;
      default:
        data._infEmailAuth = 'Unauthenticated';
        break;
    }
    data._infDateCreated = format(new Date(data.infDateCreated), 'MM/dd/yyyy');
    data._infDateUpdated = format(new Date(data.infDateUpdated), 'MM/dd/yyyy');
    return data;
  };

  useEffect(() => {
    async function loadData() {
      const {
        match: {
          params: { id },
        },
      } = props;

      if (id === 'new') {
        // New Data
        setNewData(true);
        viewContent();
      } else {
        const apiGetData = api('GET', `/influencer/${id}`, null);

        const resGetData = await apiGetData;
        const {
          data: { resData },
        } = resGetData;
        // console.log(resData);
        setData(auxFormatData(resData));
        viewContent();
      }
    }
    try {
      loadData();
    } catch (error) {
      viewError();
    }
  }, []);

  const viewData = () => {
    return (
      <S.DivData>
        <NavTabsGeneral
          propContext={objData}
          propTabs={[
            {
              title: 'Info',
              component: <TabInfo propData={objData} propNewData={isNewData} />,
            },
            !isNewData && {
              title: 'Images',
              component: <TabImage propData={objData} />,
            },
            !isNewData && {
              title: 'Users',
              component: <TabUser propData={objData} />,
            },
          ]}
        />
      </S.DivData>
    );
  };

  const viewLoading = status => {
    if (status === undefined) {
      console.log('type A');
      setContent(false);
      setError(false);
      setLoading(true);
    } else {
      console.log('type B');
      setLoading(status);
    }
  };

  const viewError = () => {
    setContent(false);
    setLoading(false);
    setError(true);
  };

  const viewContent = () => {
    setLoading(false);
    setError(false);
    setContent(true);
  };

  return (
    <>
      <NavBar />
      <S.Container>
        <S.ContainerHeader>
          <S.Title>Influencer</S.Title>
          <Line />
        </S.ContainerHeader>
        <S.ContainerContent>
          <InfluencerManagerContext.Provider
            value={{ viewLoading, viewError, viewContent }}
          >
            {isLoading && <Loader />}
            {isError && <Error />}
            {isContent && viewData()}
            {/* {isLoading ? <Loader /> : viewData()} */}
          </InfluencerManagerContext.Provider>
        </S.ContainerContent>
      </S.Container>
      <NotificationContainer />
    </>
  );
};

export default InfluencerManager;
