import styled from 'styled-components';
import media from 'styled-query';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  height: 100%;
  justify-content: space-between;
  overflow: auto;
`;

export const Type = styled.div`
  display: grid;
  grid-template-rows: 50px auto;
  height: 100%;
  padding-bottom: 50px;
`;

export const Title = styled.h1`
  text-align: start;
  color: #1c242e;
`;

export const GridUser = styled.div`
  display: grid;
  height: 300px;
  /* grid-template-columns: 1fr 2fr 2fr 2fr; */
  grid-template-columns: 1fr 2fr 2fr;
  gap: 50px;

  ${media.lessThan('small')`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  `}
`;

export const Grid = styled.div`
  display: grid;
  height: 300px;
  /* grid-template-columns: 1fr 2fr 2fr 2fr; */
  grid-template-columns: 1fr 2fr 2fr;
  gap: 50px;

  ${media.lessThan('small')`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  `}
`;
