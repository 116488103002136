import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';
import api from '../../services/api';

import NavBar from '../../components/NavBar';
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import Line from '../../components/Line';
import ButtonNew from '../../components/ButtonNew';

import * as S from './styles';
import { led } from '../../utils/led';
import { userPermission } from '../../utils/user-permission';

const User = () => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [lstData, setData] = useState([]);
  const objColumns = [
    {
      title: '',
      field: '_useStatus',
      formatter: 'html',
      width: 15,
      align: 'center',
      // headerFilter: 'select',
      // headerFilterParams: {
      //   values: {
      //     0: 'Inactive',
      //     1: 'Active',
      //   },
      // },
    },
    { title: 'Permission', field: '_usePermission', headerFilter: 'input', align: 'center', width: 120 },
    { title: 'Name', field: 'useName', headerFilter: 'input' },
    { title: 'Last Name', field: 'useNameLast', headerFilter: 'input' },
    { title: 'Email', field: 'useEmail', headerFilter: 'input' },
    { title: 'Device', field: 'payDevice', headerFilter: 'input', align: 'center', width: 120 },
    { title: 'Plan', field: '_plaPaid', headerFilter: 'input', align: 'center', width: 80 },
    {
      title: 'Registered',
      field: '_useDateCreated',
      sorter: 'date',
      sorterParams: { format: 'MM/DD/YYYY' },
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 120,
    },
  ];

  const auxFormatData = obj => {
    const objData = obj;
    objData.id = obj.useId;
    objData._plaPaid = obj.plaId === '969a002f-0d53-41bb-861b-2fd121a6959e' ? 'Free' : 'Paid';
    objData._useStatus = led(objData.useStatus);
    objData._usePermission = userPermission(objData.usePermission);
    objData._useDateCreated = format(
      new Date(obj.useDateCreated || obj.useDate),
      'MM/dd/yyyy'
    );
    return objData;
  };

  useEffect(() => {
    async function loadData() {
      const resGetData = await api('GET', '/user', null);
      const {
        data: { resData },
      } = resGetData;
      const lstDataFormated =
        resData !== null ? resData.map(auxFormatData) : [];
      setLoading(false);
      setData(lstDataFormated);
      console.log(lstDataFormated);
    }

    try {
      loadData();
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }, []);

  const viewData = () => {
    return !isError ? (
      <Table
        propColumns={objColumns}
        propData={lstData}
        propDblClickRedirect="/user"
      />
    ) : (
      <Error />
    );
  };

  return (
    <>
      <NavBar />
      <S.Container>
        <S.ContainerHeader>
          <S.ContainerTitle>
            <S.Title>Users</S.Title>
            {/* <ButtonNew propLink="/users/new" /> */}
          </S.ContainerTitle>
          <Line />
        </S.ContainerHeader>
        <S.ContainerContent>
          {isLoading ? <Loader /> : viewData()}
        </S.ContainerContent>
      </S.Container>
    </>
  );
};

export default User;
