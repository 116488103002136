import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const ButtonLoader = ({ propTitle, propSpinner, propHeight }) => {
  return (
    <S.Button type="submit" disabled={propSpinner} propHeight={propHeight}>
      {propSpinner && (
        <S.Spinner type="Bars" color="#ffffff" height={25} width={25} />
      )}
      {propTitle}
    </S.Button>
  );
};

ButtonLoader.defaultProps = {
  propTitle: 'TITLE',
  propHeight: 45,
};

ButtonLoader.propTypes = {
  propTitle: PropTypes.string,
  propHeight: PropTypes.number,
  propSpinner: PropTypes.bool.isRequired,
};

export default ButtonLoader;
