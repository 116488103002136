import React from 'react';

import * as S from './styles';

const Error = () => {
  return (
    <S.Container>
      <S.ContainerError>
        <S.ImgError />
        <S.Button onClick={() => window.location.reload()}>RELOAD</S.Button>
      </S.ContainerError>
    </S.Container>
  );
};

export default Error;
