import React from 'react';

import NavBar from '../../components/NavBar';
import Reports from '../../components/Reports';
import Dash from '../../components/Dash';

import { Container } from './styles';

const Main = () => {
  return (
    <>
      <NavBar />
      <Container>
        {/* <Reports /> */}
        <Dash />
      </Container>
    </>
  );
};

export default Main;
