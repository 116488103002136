import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import * as S from './styles';

const Table = ({
  propOptions,
  propColumns,
  propData,
  propDblClickRedirect,
  propDblClickAction,
}) => {
  const history = useHistory();
  // function auxClick() {
  //   console.log('click');
  // }
  function auxDblClick(e, row) {
    // console.log(e);
    // console.log(row);
    const {
      _row: { data: objData },
    } = row;
    if (propDblClickRedirect !== 'undefined') {
      history.push(`${propDblClickRedirect}/${objData.id || ''}`);
    }
    propDblClickAction(objData.id);
  }

  return (
    <S.ReactTabulator
      data={propData}
      columns={propColumns}
      options={propOptions}
      // rowClick={() => auxClick()}
      rowDblClick={(e, row) => auxDblClick(e, row)}
      tooltips={true} // kes-dev
      layout={"fitData"} // kes-dev apenas string
    />
  );
};

Table.defaultProps = {
  propOptions: {
    height: 510,
    movableRows: false,
    pagination: 'local',
    paginationSize: 13,
  },
  propDblClickRedirect: 'undefined',
  propDblClickAction: () => {},
};

Table.propTypes = {
  propOptions: PropTypes.instanceOf(Object),
  propColumns: PropTypes.instanceOf(Array).isRequired,
  propData: PropTypes.instanceOf(Array).isRequired,
  propDblClickRedirect: PropTypes.string,
  propDblClickAction: PropTypes.func,
};

export default Table;
