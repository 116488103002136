import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import 'react-notifications/lib/notifications.css';
import './custom.css';
import * as S from './styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}
function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  divDropzone: {
    borderRadius: "4px",
    padding: "30px",
    textAlign: "center",
    border: "1px dashed #e4c1cb",
    cursor: "pointer",
    color: "#e71a69",
    "& > p": {
      margin: 0,
      fontSize: "15px",
    },
    "&:hover": {
      border: "2px dashed #e71a69",
    },
    "&:disabled": {
      border: "2px dashed #b7b7b7",
      color: "#b7b7b7",
    },
  },
  divDropzoneDisabled: {
    borderRadius: "4px",
    padding: "30px",
    textAlign: "center",
    cursor: "progress",
    border: "2px dashed #b7b7b7",
    color: "#b7b7b7",
    "& > p": {
      margin: 0,
      fontSize: "18px",
    },
  },
  modalRoot: {
    overflow: "auto",
    display: "block"
  },
  modal: {
    maxWidth: "250px",
    margin: "auto",
    borderRadius: "6px",
    overflow: "visible",
    maxHeight: "unset",
    width: "100%",
    marginTop: "130px !important",
  },
  modalHeader: {
    borderBottom: "none",
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "0",
    paddingLeft: "24px",
    minHeight: "16.43px"
  },
  modalTitle: {
    margin: "0",
    lineHeight: "1.5",
    textAlign: "center",
    color: "#03163d",
  },
  modalBody: {
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "16px",
    paddingLeft: "24px",
    position: "relative",
    overflow: "visible",
    textAlign: "center",
  },
  imgProgress: {
    width: "75px",
  },
  imgProgressS: {
    width: "75px",
    padding: "15px",
  },
}));

const NavTabsGeneral = ({ propContext, propTabs }) => {
  // console.log('context'); kes-dev ???
  // console.log(propContext); kes-dev ???

  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <S.Container>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
          >
            {propTabs.map((item, i) => {
              return <LinkTab key={i} label={item.title} href="/" {...a11yProps(i)} />
            })}
          </Tabs>
        </AppBar>
        {propTabs.map((item, i) => {
          return (
            <TabPanel key={i} value={value} index={i}>
              <S.DivTab>
                {item.component}
              </S.DivTab>
            </TabPanel>
          )
        })}
      </div>
    </S.Container>
  );
};

NavTabsGeneral.defaultProps = {
  propContext: {},
  propTabs: [
    {
      title: 'undefined',
      component: <h1>Undefined</h1>
    }
  ],
};

NavTabsGeneral.propTypes = {
  propContext: PropTypes.object,
  propTabs: PropTypes.array,
};

export default NavTabsGeneral;
