import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';

const RatingView = ({ propValue }) => {
  // const [value, setValue] = useState(0);
  // console.log('star');
  // useEffect(() => {
  //   setValue(parseFloat(`${Math.floor(`${propValue}e+${2}`)}e-${2}`));
  // }, [propValue]);

  return (
    <Rating name="half-rating" value={propValue} precision={0.5} readOnly />
  );
};

RatingView.propTypes = {
  propValue: PropTypes.number.isRequired,
};

export default RatingView;
