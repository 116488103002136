import styled from 'styled-components';
import media from 'styled-query';
import {
  Form as oForm,
  Input as oInput,
  Select as oSelect,
} from '@rocketseat/unform';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Form = styled(oForm)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  flex: 1;

  small {
    /* color: #3079ff; */
    color: #4a8aff;
    margin-left: 3px;
    margin-bottom: 5px;
  }

  img {
    width: 100%;
  }

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    flex-direction: column;
  `}
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  margin: 0 1rem 1rem 1rem;
  /* width: calc(25% - 2rem); */

  width: calc(${props => props.propW} - 2rem);

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    width: 100%;
    margin: 0;
  `}
`;

export const Input = styled(oInput)`
  background: #fff;
  border: 1px solid #00bfff;
  color: #545454;
  border-radius: 5px;
  font-size: 15px;

  &:disabled {
    background: #a7bad43d;
    color: #545454b5;
    cursor: not-allowed;
  }
`;

export const Select = styled(oSelect)`
  background: #fff;
  border: 1px solid #00bfff;
  color: #545454;
  border-radius: 5px;
  height: 35px;
  font-size: 15px;

  &:disabled {
    background: #a7bad43d;
    color: #545454b5;
    cursor: not-allowed;
  }
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 25px 0;
  width: 100%;
  button {
    width: 150px;
  }
`;
