import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);
  margin-top: 75px;

  background-color: #fff;

  padding: 5vw;
  /* text-align: center; */
  /* background-color: #c4d8ff; */
`;

export const ContainerHeader = styled.div`
  margin-bottom: 5vh;
`;

export const Title = styled.h1`
  color: #3079ff;
`;

export const ContainerContent = styled.div`
  height: 100%;
`;

export const DivData = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ffebeb;
  background: #fff;
`;