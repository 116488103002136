import styled from 'styled-components';
import { Link as oLink } from 'react-router-dom';

export const Link = styled(oLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #00bfff;
  &:hover {
    color: #00ffaa;
  }
`;

export const IconWrapper = styled.div`
  //Preenchimento Inicial
  fill: #bbb;
  width: 30px;
  height: 30px;
`
