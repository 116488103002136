import React, { useEffect } from 'react';

import { Redirect } from 'react-router-dom';
import { authSignOut } from '../../services/auth';

const Logout = () => {
  useEffect(() => {
    authSignOut();
  }, []);

  return <Redirect to="/login" />;
};

export default Logout;
