import React from 'react';

import * as S from './styles';

export default function ReportsItem ({title,value}) {
  return (
    <S.Container>
      <h3>{title}</h3>
      <h1>{value}</h1>
    </S.Container>
  );
}
