import React, { useState, useEffect } from 'react';

import ReactPlayer from 'react-player';
import api from '../../services/api';

import NavBar from '../../components/NavBar';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import Line from '../../components/Line';

import * as S from './styles';

const Player = props => {
  const player = React.createRef();
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [objData, setData] = useState({});

  useEffect(() => {
    async function loadData(id) {
      const resGetData = await api('GET', `/song/${id}`, null);
      const {
        data: { resData },
      } = resGetData;

      console.log(resData);
      setData(resData);
      setLoading(false);
    }

    try {
      const {
        match: {
          params: { id },
        },
      } = props;
      console.log(id);
      loadData(id);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }, []);

  const viewData = () => {
    return !isError ? (
      <S.DivPlayer propW="100%">
        <ReactPlayer
          ref={player}
          url={objData.sonLinkHls}
          playing
          controls
          // pip={true}
          onStart={() => {
            console.log('start');
            setError(false);
          }}
          onError={e => {
            console.log(e);
            // setError(true);
          }}
        />
      </S.DivPlayer>
    ) : (
      <Error />
    );
  };

  return (
    <>
      <NavBar />
      <S.Container>
        <S.ContainerHeader>
          <S.ContainerTitle>
            <S.Title>Player</S.Title>
          </S.ContainerTitle>
          <Line />
        </S.ContainerHeader>
        <S.ContainerContent>
          {isLoading ? <Loader /> : viewData()}
        </S.ContainerContent>
      </S.Container>
    </>
  );
};

export default Player;
