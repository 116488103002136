export function userPermission(status) {
  switch (status) {
    case 0:
      return 'user'
    case 1:
      return 'plus'
    case 2:
      return 'moderator'
    case 3:
      return 'admin'
    default:
      return '[null]'
  }
}
