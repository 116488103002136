import aws4 from 'aws4';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import config from '../config/aws';

const api = async (apiMethod, apiPath, apiParameters) => {
  const user = await Auth.currentCredentials();
  const objUser = {
    secretAccessKey: user.secretAccessKey,
    accessKeyId: user.accessKeyId,
    sessionToken: user.sessionToken,
  };

  const requestGet = {
    method: apiMethod,
    // ZTV ----------------------------------------------------------------------------
    // url: `https://${config.apiGateway.URL}.execute-api.${config.apiGateway.REGION}.amazonaws.com/prod${apiPath}/`,
    url: `https://${config.api.BASE_URL}${apiPath}/`,
    // host: `${config.apiGateway.URL}.execute-api.${config.apiGateway.REGION}.amazonaws.com`,
    host: `${config.api.BASE_URL}`,
    // path: `/prod${apiPath}/`,
    path: `${apiPath}/`,
    service: 'execute-api',
    region: config.apiGateway.REGION,
    data: apiParameters,
    // data: {}, // object describing the foo
    // body: apiParameters, // aws4 looks for body; axios for data
    // body: JSON.stringify(apiParameters), // aws4 looks for body; axios for data
    headers: {
      'content-type': 'application/json',
      'token_api_adm': 'OQ!eNDZSvWJiSv!T',
    },
  };

  const requestPostPut = {
    method: apiMethod,
    // ZTV ----------------------------------------------------------------------------
    // url: `https://${config.apiGateway.URL}.execute-api.${config.apiGateway.REGION}.amazonaws.com/prod${apiPath}/`,
    url: `https://${config.api.BASE_URL}${apiPath}/`,
    // host: `${config.apiGateway.URL}.execute-api.${config.apiGateway.REGION}.amazonaws.com`,
    host: `${config.api.BASE_URL}`,
    // path: `/prod${apiPath}/`,
    path: `${apiPath}/`,
    service: 'execute-api',
    region: config.apiGateway.REGION,
    data: apiParameters,
    // data: {}, // object describing the foo
    // body: apiParameters, // aws4 looks for body; axios for data
    body: JSON.stringify(apiParameters), // aws4 looks for body; axios for data
    headers: {
      'content-type': 'application/json',
      'token_api_adm': 'OQ!eNDZSvWJiSv!T',
    },
  };

  const request = apiMethod === 'GET' ? requestGet : requestPostPut;

  const signedRequest = aws4.sign(request, objUser);

  delete signedRequest.headers.Host;
  delete signedRequest.headers.Host;
  delete signedRequest.headers['Content-Length'];

  const response = await axios(signedRequest);

  return response;
};

export default api;
