import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';
import api from '../../services/api';

import NavBar from '../../components/NavBar';
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import Line from '../../components/Line';
import ButtonNew from '../../components/ButtonNew';

import * as S from './styles';

const Catalog = () => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [lstData, setData] = useState([]);
  const objColumns = [
    {
      title: '',
      field: '_catStatus',
      formatter: 'html',
      width: 15,
      align: 'center',
      // headerFilter: 'select',
      // headerFilterParams: {
      //   values: {
      //     0: 'Inactive',
      //     1: 'Active',
      //   },
      // },
    },
    { title: 'Name', field: 'catName', headerFilter: 'input' },
    { title: 'Description', field: 'catDescription', headerFilter: 'input' },
    {
      title: 'Created',
      field: '_catDateCreated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
    {
      title: 'Updated',
      field: '_catDateUpdated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
  ];

  const auxFormatData = objData => {
    objData.id = objData.catId;
    objData._catStatus =
      objData.catStatus === 0
        ? '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #ABFF00; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;"></div>'
        : '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #FF0000; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #470101 0 -1px 9px, #ff0000 0 2px 12px;"></div>';
    objData._catDateCreated = format(new Date(objData.catDateCreated), 'MM/dd/yyyy');
    objData._catDateUpdated = format(new Date(objData.catDateUpdated), 'MM/dd/yyyy');
    return objData;
  };

  useEffect(() => {
    async function loadData() {
      const resGetData = await api('GET', '/catalog', null);
      const {
        data: { resData },
      } = resGetData;

      console.log(resData);
      const tmpData = resData !== null ? resData.map(auxFormatData) : [];
      setData(tmpData);
      setLoading(false);
    }

    try {
      loadData();
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }, []);

  // propDblClickRedirect="/artist"
  // propDblClickRedirect="undefined"
  const viewData = () => {
    return !isError ? (
      <Table
        propColumns={objColumns}
        propData={lstData}
        propDblClickRedirect="/catalog"
      />
    ) : (
      <Error />
    );
  };

  return (
    <>
      <NavBar />
      <S.Container>
        <S.ContainerHeader>
          <S.ContainerTitle>
            <S.Title>Catalogs</S.Title>
            <ButtonNew propLink="/catalog/new" />
          </S.ContainerTitle>
          <Line />
        </S.ContainerHeader>
        <S.ContainerContent>
          {isLoading ? <Loader /> : viewData()}
        </S.ContainerContent>
      </S.Container>
    </>
  );
};

export default Catalog;
