import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import ReactPlayer from 'react-player';
import Dropzone from 'react-dropzone';
import {
  NotificationContainer,
  NotificationManager as N,
} from 'react-notifications';

import { useHistory } from 'react-router-dom';

import config from '../../../../config/aws';
import api from '../../../../services/api';
import { fileUploadSong } from '../../../../services/s3';
import InfluencerManagerContext from '../../InfluencerManagerContext';

import imgLoading from '../../../../assets/images/loading/icoLoading-2.svg';
import imgSuccess from '../../../../assets/images/loading/icoSuccess-1.svg';
import imgSearch from '../../../../assets/images/loading/icoSearch-1.svg';

import CustomLinearProgress from '../../../../components/CustomLinearProgress';
import Loader from '../../../../components/Loader';
import ButtonLoader from '../../../../components/ButtonLoader';
import RatingView from '../../../../components/RatingView';
import ButtonNew from '../../../../components/ButtonNew';
import ButtonAdd from '../../../../components/ButtonAdd';
import ButtonBack from '../../../../components/ButtonBack';
import BadgeArtist from '../../../../components/BadgeArtist';
import Table from '../../../../components/Table';

import 'react-notifications/lib/notifications.css';
import * as S from './styles';

const User = ({ propData }) => {
  const history = useHistory();

  const { viewLoading, viewError, viewContent } = useContext(
    InfluencerManagerContext
  );
  const [lstData, setDataTable] = useState([]);
  const [objDataMain, setDataMain] = useState(propData);
  const [isStatus, setStatus] = useState(false);
  const [isManagerData, setManagerData] = useState(false);
  const [isManagerDataNew, setManagerDataNew] = useState(false);
  const [lstSongArtist, setSongArtist] = useState([]);
  const [objSong, setSong] = useState({});
  const [songLinkPlayer, setSongLinkPlayer] = useState(null);
  const [isDropzone, setIsDropzone] = useState(true);
  const [lstArtist, setArtist] = useState([]);

  const [upProgress, setUpProgress] = useState(0);
  const [modUpload, setModUpload] = useState(false);
  const [modArtist, setModArtist] = useState(false);

  const player = React.createRef();

  const objColumns = [
    { title: 'Code', field: 'infId', headerFilter: 'input' },
    { title: 'Username', field: 'infUseName', headerFilter: 'input' },
    {
      title: 'Created',
      field: '_infUseDateCreated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
  ];

  const auxFormatData = objData => {
    objData.id = objData.infKey.substr(5);
    objData._infUseDateCreated = format(
      new Date(objData.infUseDateCreated),
      'MM/dd/yyyy'
    );
    return objData;
  };

  useEffect(() => {
    async function loadData() {
      const apiGetData = api('GET', `/influencer/${objDataMain.infId}/user`, null);
      const resGetData = await apiGetData;
      const {
        data: { resData },
      } = resGetData;

      console.log(resData);
      const tmpData = resData.length > 0 ? resData.map(auxFormatData) : [];
      setDataTable(tmpData);

      viewContent();
      setStatus(true);
    }
    try {
      loadData();
    } catch (error) {
      console.error(error);
      viewError();
    }
  }, []);

  const fncGetSound = async sonId => {
    try {
      const apiGetData = api('GET', `/song/${sonId}`, null);
      const resGetData = await apiGetData;
      const {
        data: { resData },
      } = resGetData;
      console.log(resData);
      const tmpData = resData !== null ? resData.ARTIST.map(obj => obj) : [];
      setSongArtist(tmpData);
    } catch (error) {
      setSongArtist(['undefined']);
    }
  };

  const handleViewSong = async sonId => {
    // console.log('handleViewSong');
    // console.log(sonId);
    // await fncGetSound(sonId);
    // const objResult = objDataMain.SONG.find(obj => obj.sonId === sonId);
    // setSong(objResult);
    // setSongLinkPlayer(objResult.sonLinkHls);
    // setManagerDataNew(false);
    // setManagerData(true);
  };

  const handleNewSong = () => {
    console.log('handleNewSong');
    console.log('new');
    setSong({
      _sonStatus: null,
      _sonDateCreated: '',
      _sonDateUpdated: '',
      _sonItem: '',
      sonName: '',
      sonType: 'Song',
      labId: objDataMain.LABEL.labId,
      _labName: objDataMain.LABEL.labName,
      catId: objDataMain.CATEGORY.catId,
      _catName: objDataMain.CATEGORY.catName,
      sonYearReleased: null,
      _sonExplicitLyrics: null,
      sonAbout: '',
    });
    setSongLinkPlayer(null);
    setManagerDataNew(true);
    setManagerData(true);
  };

  const handleListSong = () => {
    console.log('handleListSong');

    async function loadData() {
      const apiGetData = api('GET', `/album/${objDataMain.albId}`, null);

      const resGetData = await apiGetData;
      const {
        data: { resData },
      } = resGetData;
      console.log(resData);
      const tmpData = resData !== null ? auxFormatDataInfluencer(resData) : {};
      const tmpDataSong =
        tmpData !== null ? tmpData.SONG.map(auxFormatData) : [];

      setDataMain(tmpData);
      setDataTable(tmpDataSong);
      setStatus(true);
      setManagerData(false);

      function auxFormatDataInfluencer(data) {
        data.id = data.albId;
        switch (data.albStatus) {
          case 0:
            data._albStatus = 'Active';
            break;
          case 1:
            data._albStatus = 'Processing';
            break;
          case 2:
            data._albStatus = 'Disabled';
            break;
          case 3:
            data._albStatus = 'Blocked';
            break;
          default:
            data._albStatus = 'Disabled';
            break;
        }
        switch (data.albExplicitLyrics) {
          case 0:
            data._albExplicitLyrics = 'Not';
            break;
          case 1:
            data._albExplicitLyrics = 'Yes';
            break;
          default:
            data._albExplicitLyrics = 'Not';
            break;
        }
        // LABEL
        data._albLabel = data.LABEL.labName;
        // CATEGORY
        data._albCategory = data.CATEGORY.catName;
        // ARTIST
        data._albArtist = '';
        data.ARTIST.map((item, i) => {
          data._albArtist += `${item.artName}${
            i !== data.ARTIST.length - 1 ? ' | ' : ''
          }`;
        });
        data._albDateCreated = format(
          new Date(data.albDateCreated),
          'MM/dd/yyyy'
        );
        data._albDateUpdated = format(
          new Date(data.albDateUpdated),
          'MM/dd/yyyy'
        );
        return data;
      }
    }

    try {
      loadData();
    } catch (error) {
      console.error(error);
      viewError();
    }
  };

  const handleSubmit = async data => {
    try {
      if (handleValidate()) {
        if (isManagerDataNew) {
          // viewLoading();
          setStatus(false);
          data = {
            ...data,
            albId: objDataMain.albId,
          };
          console.log(`Data POST`, data);
          const resPostData = await api('POST', `/song`, data);
          console.log(resPostData);

          N.success('Successfully created');

          setTimeout(() => {
            // history.push(`/album/${resPostData.data.resData.albId}`); aaaaaaaaaaaaaaaaaaaa
            // window.location.reload();

            // setStatus(true);
            handleListSong();
          }, 1500);
        } else {
          setStatus(false);
          console.log(`Data PUT`, data);
          const resPutData = await api('PUT', `/song/${objSong.id}/info`, data);
          console.log(resPutData);

          N.success('Successfully updated');

          setTimeout(() => {
            // window.location.reload(); aaaaaaaaaaaaaaaaaa
            setSong({ ...objSong, ...data });
            setStatus(true);
          }, 1500);
        }
      }

      function handleValidate() {
        let varControl = true;

        // console.log(data)
        if (data._sonStatus !== 'Active' && data._sonStatus !== 'Disabled') {
          varControl = false;
          N.error('Status', 'Check parameters');
        } else {
          switch (data._sonStatus) {
            case 'Active':
              data.sonStatus = 0;
              break;
            case 'Disabled':
              data.sonStatus = 2;
              break;
            default:
              varControl = false;
              N.error('Error Status', 'Check parameters');
              break;
          }
        }

        if (data._sonItem == '' || data._sonItem == 0) {
          varControl = false;
          N.error('Item', 'Check parameters');
        } else {
          const tmpSonItem = parseInt(data._sonItem);
          if (tmpSonItem > 0 && tmpSonItem < 100) {
            data.sonItem = tmpSonItem;
          } else {
            varControl = false;
            N.error('Item [invalid]', 'Check parameters');
          }
        }

        if (data.sonName === '') {
          varControl = false;
          N.error('Name', 'Check parameters');
        }

        if (data.sonType === '') {
          varControl = false;
          N.error('Type', 'Check parameters');
        }

        if (data.sonYearReleased === '') {
          varControl = false;
          N.error('Year Released', 'Check parameters');
        } else {
          data.sonYearReleased = parseInt(data.sonYearReleased);
        }

        if (
          data._sonExplicitLyrics !== 'Yes' &&
          data._sonExplicitLyrics !== 'Not'
        ) {
          varControl = false;
          N.error('Explicit Lyrics', 'Check parameters');
        } else {
          switch (data._sonExplicitLyrics) {
            case 'Not':
              data.sonExplicitLyrics = 0;
              break;
            case 'Yes':
              data.sonExplicitLyrics = 1;
              break;
            default:
              varControl = false;
              N.error('Explicit Lyrics', 'Check parameters');
              break;
          }
        }

        return varControl;
      }
    } catch (error) {
      console.error(error);
      N.error('Error save');
      viewContent();
    }
  };

  const mediaHandleUpload = async file => {
    console.log('mediaHandleUpload');
    console.log(file[0]);

    // Referencing file
    // file.current = file[0];

    if (file[0] && file[0].size > config.S3_SONG.MAX_ATTACHMENT_SIZE) {
      // console.log("sim");
      N.error(
        `Please pick a file smaller than ${config.S3_SONG.MAX_ATTACHMENT_SIZE /
          1000000} MB.`,
        'Click me!'
      );
      return;
    }

    try {
      setUpProgress(0);
      setIsDropzone(false);
      setModUpload(true);
      const fileUpload = file[0]
        ? await fileUploadSong(objSong.id, 'song', file[0], progress => {
            if (progress < 94) {
              setUpProgress(progress);
            }
          })
        : null;

      console.log(fileUpload);

      // PUT Song
      const data = {
        sonLinkHls: fileUpload.link,
      };
      console.log(`Data PUT`, data);
      const resPutData = await api('PUT', `/song/${objSong.id}/media`, data);
      console.log(resPutData);

      setSongLinkPlayer(fileUpload.link);
      setUpProgress(100);
      N.success('Successfully uploaded');

      setTimeout(() => {
        setIsDropzone(true);
        setModUpload(false);
      }, 1000);
    } catch (e) {
      console.error(e);
      N.error(`Error upload`);
      setIsDropzone(true);
      setModUpload(false);
    }
  };

  const mediaHandleUploadError = file => {
    console.log('mediaHandleUploadError');
    console.error(file);
  };

  const handleCloseArtist = async () => {
    setModArtist(false);
  };

  const handleViewArtist = async () => {
    const auxFormatData = objData => {
      objData.id = objData.artId;
      objData._artStatus =
        objData.artStatus === 0
          ? '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #ABFF00; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;"></div>'
          : '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #FF0000; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #470101 0 -1px 9px, #ff0000 0 2px 12px;"></div>';
      objData._artDateCreated = format(
        new Date(objData.artDateCreated),
        'MM/dd/yyyy'
      );
      objData._artDateUpdated = format(
        new Date(objData.artDateUpdated),
        'MM/dd/yyyy'
      );
      return objData;
    };

    const resGetData = await api('GET', '/artist', null);
    const {
      data: { resData },
    } = resGetData;

    console.log(resData);
    const tmpData = resData !== null ? resData.map(auxFormatData) : [];

    setArtist(tmpData);
    setModArtist(true);
  };

  const handleAddArtist = async artId => {
    console.log('handleAddArtist');
    console.log(artId);

    try {
      const resGetData = await api('POST', `/song/${objSong.id}/artist`, {
        artId,
        albId: objDataMain.albId,
      });
      const {
        data: { resData },
      } = resGetData;
      // console.log(resData);

      N.success('Successfully inserted');

      handleViewSong(objSong.id);
      setModArtist(false);
    } catch (error) {
      N.error('Artist', 'Error while inserting');
    }
  };

  const handleRemoveArtist = async artId => {
    console.log('handleRemoveArtist');
    console.log(artId);

    try {
      const resGetData = await api('DELETE', `/song/${objSong.id}/artist`, {
        artId,
      });
      const {
        data: { resData },
      } = resGetData;
      console.log(resData);

      N.success('Successfully removed');

      handleViewSong(objSong.id);
    } catch (error) {
      N.error('Artist', 'Error removing');
    }
  };

  return (
    <>
      {isStatus ? (
        <Table
          propColumns={objColumns}
          propData={lstData}
          propDblClickRedirect="undefined"
          propDblClickAction={handleViewSong}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

User.defaultProps = {
  propData: {},
};

User.propTypes = {
  propData: PropTypes.object,
};

export default User;
